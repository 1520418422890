<template>
  <div class="data-box">
    <van-nav-bar
        title="开始派餐"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <div class="span-key">用餐类别</div>
          <div class="span-input" @click="editDiningType">
            <span>{{ mealsDiningType[(mealsInfo.type - 2)].name }}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">派送份数</div>
          <div class="span-input">
            <van-field
                v-model="mealsInfo.personFoodMileNum"
                placeholder="请输入数量"
                type="digit"
                maxlength="8"
            >
              <template #extra>
                <span>份</span>
              </template>
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">派餐位置</div>
          <div class="span-input">
            <van-field
                v-model="mealsInfo.idResidueSite"
                placeholder="请输入派餐位置"
            />
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">派餐人员</div>
          <div class="span-input" @click="editDiner">
            <span>{{mealsInfo.person ? mealsInfo.person : '请选择派餐人员'}}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">通知领餐人</div>
          <div class="span-value align-right">
            <van-switch v-model="mealsInfo.isInform" active-color="#3476FF" inactive-color="#FFFFFF" :active-value="true" :inactive-value="false"/>
          </div>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-item" style="align-items: flex-start;">
          <div class="span-key span-top">领餐位置</div>
          <div class="span-value">
            <van-uploader name="address" v-model="mealsInfo.addressImages" :after-read="afterRead" max-count="9" accept="image/*" multiple>
              <template>
                <div class="upload-div">
                  <i class="iconfont icon-plus-round"></i>
                </div>
              </template>
            </van-uploader>
          </div>
        </div>
        <div class="body-div-item" style="align-items: flex-start;">
          <div class="span-key span-top">餐食图片</div>
          <div class="span-value">
            <van-uploader name="meals" v-model="mealsInfo.mealsImages" :after-read="afterRead" max-count="9" accept="image/*" multiple>
              <template>
                <div class="upload-div">
                  <i class="iconfont icon-plus-round"></i>
                </div>
              </template>
            </van-uploader>
          </div>
        </div>
      </div>
    </div>
    <div class="data-box-foot">
      <van-button class="btn" round block type="default" @click="onCancel">取消</van-button>
      <van-button class="btn" round block type="info" @click="onSure">确认派餐</van-button>
    </div>
    <van-action-sheet v-model="showDiner" :actions="mealsDiners" @select="selectDiner"/>
    <van-action-sheet v-model="showDiningType" :actions="mealsDiningType" @select="selectDiningType"/>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  personCarSign,
  activityList
} from "@/api/index";
import upload from "@/assets/common/upload";

export default {
  name: "orgMeals_edit",
  data() {
    return {
      mealsInfo: {
        activityId: null,
        type: 2,
        isInform: false,
        personFoodMileNum: null,
        idResidueSite: "",
        statusSendId: null,
        person: "",
        picture1: "",
        picture2: "",
        addressImages: [],
        mealsImages: []
      },
      showDiningType: false,
      mealsDiningType: [
        {type: 2,name: "早餐"},
        {type: 3,name: "午餐"},
        {type: 4,name: "晚餐"},
        {type: 5,name: "宵夜"}
      ],
      showDiner: false,
      mealsDiners: []
    };
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      that.mealsInfo.activityId = that.$store.getters.getActivityId;
      that.getActObjList();
    },
    //获取活动对象信息
    getActObjList(){
      let that = this;
      let params = {
        "actId": that.$store.getters.getActivityId,
        "companyId": that.$store.getters.getCompanyId,
        "isShow": 0,
        "pageNum": 1,
        "pageSize": 9999,
        "postType": 3,
        "type": 1
      };
      activityList(params).then(res => {
        that.$tool.resValidation(res,() => {
          let array = res.data.records;
          array.forEach(item => {
            item.activitySets.forEach(value => {
              if(value.relateType === 14){
                value.name = item.chargeName;
                that.mealsDiners.push(value);
              }
            })
          })
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    editDiningType(){
      let that = this;
      that.showDiningType = true;
    },
    selectDiningType(item){
      let that = this;
      that.mealsInfo.type = item.type;
      that.showDiningType = false;
    },
    editDiner(){
      let that = this;
      that.showDiner = true;
    },
    selectDiner(item){
      let that = this;
      that.mealsInfo.statusSendId = item.chargeObjId;
      that.mealsInfo.person = item.name;
      that.showDiner = false;
    },
    afterRead(file,detail){
      let that = this;
      upload.uploadFile(file,res => {
        if(detail.name === "meals"){
          that.mealsInfo.mealsImages[res.index].url = res.url;
        }else if(detail.name === "address"){
          that.mealsInfo.addressImages[res.index].url = res.url;
        }
      },err => {
        console.log(err);
      });
    },
    onCancel(){
      this.$router.go(-1);
    },
    onSure(){
      let that = this;
      if(that.mealsInfo.personFoodMileNum === null){
        that.$toast('请输入派送份数！');
        return;
      }
      if(that.mealsInfo.idResidueSite === ""){
        that.$toast('请输入派餐位置！');
        return;
      }
      if(that.mealsInfo.person === ""){
        that.$toast('请输入派送人员！');
        return;
      }
      let addressArray = [];
      let mealsArray = [];
      that.mealsInfo.addressImages.forEach(item => {
        addressArray.push(item.url);
      })
      that.mealsInfo.picture1 = addressArray.join(",");
      that.mealsInfo.mealsImages.forEach(item => {
        mealsArray.push(item.url);
      })
      that.mealsInfo.picture2 = mealsArray.join(",");
      personCarSign(that.mealsInfo).then(res => {
        that.$tool.resValidation(res,() => {
          that.$toast.success('派餐成功');
          that.$router.go(-1);
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.upload-div{
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-right: 10px;
  background-color: #F6F9FF;
  border-radius: 10px;
  text-align: center;
  i{
    color: #D6E3FE;
    opacity: 0.5;
  }
}
::v-deep .van-uploader__preview {
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  .van-uploader__preview-image {
    display: block;
    width: 60px;
    height: 60px;
    overflow: hidden;
    img{
      width: 60px;
      height: 60px;
      border-radius: 10px;
    }
  }
}
::v-deep .van-switch {
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  width: 2em;
  height: 1em;
  font-size: 20px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
</style>